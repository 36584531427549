import { Outlet } from 'react-router-dom'
import { Link, NavLink } from "react-router-dom"
import irostors from "../assets/images/irostors.png"

const Layout = () => {
  return (
    <>
      <header>
        <Link className="navbar__brand" to={"/"}>
          <img src={irostors} alt="" /> <span>Event Management</span>
        </Link>
        <nav>
          <NavLink exact="true" activeclassname="active" className="navbar__link" to="/">
            Create Event
          </NavLink>
          <NavLink exact="true" activeclassname="active" className="navbar__link" to="/events">
            View Events
          </NavLink>
        </nav>
      </header>
      <Outlet />
    </>
  )
}

export default Layout