import { useState, useEffect } from "react"
import { Route, Routes } from "react-router-dom"
import Layout from "./components/Layout"
import InputArea from "./components/InputArea"
import Events from "./components/Events"
import defaultTemplates from "./assets/defaultTemplates"
import sampleEvents from "./assets/sampleEvents"
import "./App.css"

function App() {
  // Read created events and templates from Local Storage
  const [events, setEvents] = useState(JSON.parse(localStorage.getItem("storedEvents")) || sampleEvents)
  const [templates, setTemplates] = useState(JSON.parse(localStorage.getItem("storedTemplates")) || defaultTemplates)

  // Store created events and templates in Local Storage
  useEffect(() => {
    localStorage.setItem("storedEvents", JSON.stringify(events))
  }, [events])

  useEffect(() => {
    localStorage.setItem("storedTemplates", JSON.stringify(templates))
  }, [templates])

  // Add created event to events
  function addEvent(newEvent) {
    setEvents(prevEvents => {
      return [...prevEvents, newEvent]
    })
  }

  // Edit an event
  function updateEvent(updatedEvent) {
    setEvents(events.map(event => {
      if (event.nanoid === updatedEvent.nanoid) {
        return updatedEvent
      } else {
        return event
      }
    }))
  }

  // Delete an event
  function deleteEvent(EventID) {
    setEvents(events.filter(event => event.nanoid !== EventID))
  }

  // Delete a template
  function deleteTemplate(templateID) {
    setTemplates(templates.filter(template => template.nanoid !== templateID))
  }

  // To be implemented in Phase 2

  // Add recurring event

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={
            <InputArea
              onAdd={addEvent}
              templates={templates}
              setTemplates={setTemplates}
              deleteTemplate={deleteTemplate}
            />
          } />
          <Route path="edit/:id" element={
            <InputArea
              events={events}
              onUpdate={updateEvent}
              templates={templates}
              setTemplates={setTemplates}
              deleteTemplate={deleteTemplate}
            />
          } />
          <Route path="events" element={
            <Events
              events={events}
              deleteEvent={deleteEvent}
            />
          } />
        </Route>
      </Routes>
    </>
  );
}

export default App;
