import { nanoid } from "nanoid"
import addDays from 'date-fns/addDays'
import addWeeks from 'date-fns/addWeeks'

const sampleEvents = [
    {
        nanoid: nanoid(),
        title: "Seminar",
        description: "Hold these meetings with targeted audiences and provide them with relevant information.",
        time: {
            start: addDays(new Date(), 1).setHours(9, 0),
            end: addDays(new Date(), 1).setHours(10, 0)
        },
        format: "hybrid",
        location: "Building A",
        link: "https://zoom.us",
        password: "123456",
        department: "Marketing",
        person: "Bob",
        phone: "919-123-4567",
        email: "bob@domain.com",
    },
    {
        nanoid: nanoid(),
        title: "Golf Event",
        description: "Give people in the organization a venue where they will be able to build relationships in a relaxed environment. ",
        time: {
            start: addWeeks(new Date(), 1).setHours(9, 0),
            end: addDays(new Date(), 9).setHours(17, 0)
        },
        format: "physical",
        location: "Golf Course",
        link: "",
        password: "",
        department: "Sales",
        person: "Joe",
        phone: "919-123-4568",
        email: "joe@domain.com",
    },
    {
        nanoid: nanoid(),
        title: "Team-Building Event",
        description: "Boost employee confidence, goodwill, and morale.",
        time: {
            start: addDays(new Date(), 3).setHours(16, 0),
            end: addDays(new Date(), 3).setHours(17, 0)
        },
        format: "online",
        location: "",
        link: "https://zoom.us",
        password: "123456",
        department: "Human Resources",
        person: "Jimmy",
        phone: "919-123-4569",
        email: "jimmy@domain.com",
    }
]

export default sampleEvents