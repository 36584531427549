import { useState, useEffect, useRef } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"
import { nanoid } from "nanoid"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DeleteForever } from "@material-ui/icons"
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField"
import defaultEvent from "../assets/defaultEvent"

function InputArea({ events, onAdd, onUpdate, templates, setTemplates, deleteTemplate }) {
  const titleInputRef = useRef(null)
  const navigate = useNavigate()
  const { id } = useParams()
  const foundEvent = events?.find(event => event.nanoid === id)
  const [event, setEvent] = useState(defaultEvent)
  const [template, setTemplate] = useState(defaultEvent)
  const [saveAsTemplate, setSaveAsTemplate] = useState(false)
  const [sentStatus, setSentStatus] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [time, setTime] = useState({})

  useEffect(() => {
    // Auto-focus on event title on load
    titleInputRef.current.focus()

    // Load template upon user selection
    setEvent(template)
    setTime(template.time)
  }, [template, setEvent])

  // Load event for editing on URL paramater match
  useEffect(() => {
    if (foundEvent) {
      setEvent(foundEvent)
      setTime(foundEvent.time)
    }
  }, [foundEvent])

  // Handle change on user input
  function handleChange(event) {
    const { name, value } = event.target

    setEvent(prevEvent => {
      return {
        ...prevEvent,
        [name]: value
      }
    })
  }

  // Handle event submission
  function handleSubmit(e) {
    e.preventDefault()

    // Check if start date/time is before end date/time
    if (event.time.end < event.time.start) {
      alert("Error: start time after end time. Please check and re-submit.")
      return
    }

    // Update existing event or add new event
    if (id) {
      onUpdate({ ...event, time: time })
      setIsUpdate(true)
      navigate("/")
    } else {
      onAdd({ ...event, time: time, nanoid: nanoid() })
      setIsUpdate(false)
    }

    // Save event as template (optional)
    saveAsTemplate && setTemplates(prevTemplates => ([
      ...prevTemplates, { ...event, time: time, nanoid: nanoid() }
    ]))

    // Display success message after submission of event
    setSentStatus(true)

    // Clear user input after submission
    setEvent({ ...defaultEvent, nanoid: nanoid() })

    titleInputRef.current.focus()
  }

  return (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <ul>
          <div className="form__head">
            <div className="form__time">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="form__timePicker">
                  <DateTimePicker
                    label="Start Date & Time"
                    value={time?.start}
                    onChange={(newValue) => { setTime(prevTime => ({ ...prevTime, start: newValue })) }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
                <div className="form__timePicker">
                  <DateTimePicker
                    label="End Date & Time"
                    value={time?.end}
                    onChange={(newValue) => { setTime(prevTime => ({ ...prevTime, end: newValue })) }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </LocalizationProvider>
            </div>
            <TextField
              id="outlined-select"
              select
              label="Template"
              value={template}
              onChange={(event) => { setTemplate(event.target.value) }}
              helperText="Start from scratch or choose a template"
            >
              {templates?.map(template => (
                <MenuItem className="template" key={template.nanoid} value={template}>
                  <div className="template__menu">
                    {template.title}
                    <Link
                      className="template__delete"
                      to={""}
                      onClick={() => deleteTemplate(template.nanoid)}>
                      <DeleteForever />
                    </Link>
                  </div>
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="form__head">
            <input type="radio" name="format" value="physical" id="physical" checked={event.format === "physical"} onChange={handleChange} />
            <input type="radio" name="format" value="online" id="online" checked={event.format === "online"} onChange={handleChange} />
            <input type="radio" name="format" value="hybrid" id="hybrid" checked={event.format === "hybrid"} onChange={handleChange} />
            <label htmlFor="physical" className="form__format physical">
              <span>Physical</span>
            </label>
            <label htmlFor="online" className="form__format online">
              <span>Online</span>
            </label>
            <label htmlFor="hybrid" className="form__format hybrid">
              <span>Hybrid</span>
            </label>
          </div>
          <h3>Event</h3>
          <li>
            <input
              placeholder="Title"
              type="text"
              name="title"
              value={event.title}
              ref={titleInputRef}
              onChange={handleChange}
              required
            />
          </li>
          <li>
            <textarea
              placeholder="Description"
              name="description"
              value={event.description}
              onChange={handleChange}
              required
            ></textarea>
          </li>
          {(event.format === "physical" || event.format === "hybrid") &&
            <li>
              <input
                placeholder="Location"
                type="text"
                name="location"
                value={event.location}
                onChange={handleChange}
              />
            </li>
          }
          {(event.format === "online" || event.format === "hybrid") &&
            <>
              <li className="form__left">
                <input
                  placeholder="Link"
                  type="text"
                  name="link"
                  value={event.link}
                  onChange={handleChange}
                />
              </li>
              <li className="form__right">
                <input
                  placeholder="Password"
                  type="password"
                  name="password"
                  value={event.password}
                  onChange={handleChange}
                />
              </li>
            </>
          }
          <h3>Organizer</h3>
          <li className="form__left">
            <input
              placeholder="Department"
              type="text"
              name="department"
              value={event.department}
              onChange={handleChange}
              required />
          </li>
          <li className="form__right">
            <input
              placeholder="Person"
              type="text"
              name="person"
              value={event.person}
              onChange={handleChange}
              required
            />
          </li>
          <li className="form__left">
            <input
              placeholder="Phone"
              type="text"
              name="phone"
              value={event.phone}
              onChange={handleChange}
            />
          </li>
          <li className="form__right">
            <input
              placeholder="Email"
              type="email"
              name="email"
              value={event.email}
              onChange={handleChange}
              required
            />
          </li>
          <li className="checkBox">
            <label>
              <input
                type="checkbox"
                checked={saveAsTemplate}
                onChange={() => setSaveAsTemplate(prev => !prev)}
              />
              &nbsp;Save as template
            </label>
            {sentStatus ? <span onClick={() => setSentStatus(false)}>Event successfully {isUpdate ? "updated" : "created"}!</span> : <input type="submit" className="form__button" value={id ? "Update" : "Submit"} />}
          </li>
        </ul>
      </form>
    </div>
  )
}

export default InputArea
