import { useState } from "react"
import { Link } from "react-router-dom"
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function Events({ events, deleteEvent }) {
    // Show confirm delete prompt before actual delete
    const [confirmDelete, setConfirmDelete] = useState({
        show: false,
        id: null,
    })

    function handleDelete(id) {
        if (confirmDelete.show && id === confirmDelete.id) {
            deleteEvent(id)
            setConfirmDelete({
                show: false,
                id: null
            })
        }

        else {
            setConfirmDelete({
                show: true,
                id
            })
        }
    }

    // To be implemented in Phase 2

    // Add calendar view
    // Sort events by time/date, organizer
    // Filter events by title
    return (
        <div className="events">
            {events?.map(event => {
                const startDate = new Date(event.time.start).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })
                const endDate = new Date(event.time.end).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })
                const startTime = new Date(event.time.start).toLocaleString([], { hour: '2-digit', minute: '2-digit' })
                const endTime = new Date(event.time.end).toLocaleString([], { hour: '2-digit', minute: '2-digit' })

                return (
                    <div className="events__card">
                        <Card key={event.nanoid} sx={{ mb: 1.5, minWidth: 275, backgroundColor: "#CEEEFF" }}>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {event.title}
                                </Typography>
                                <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                    {startDate} {startTime} to {startDate !== endDate && endDate + " "}{endTime}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    {event.format} event
                                </Typography>
                                <Typography sx={{ fontSize: 16 }} variant="body2">
                                    Description: {event.description}
                                </Typography>
                                <br />
                                {event.location && <p>Location: {event.location}</p>}
                                {event.link && <p>Link: {event.link}</p>}
                                {event.password && <p>Password: {event.password}</p>}
                                <p>Department: {event.department}</p>
                                <p>Person: {event.person}</p>
                                {event.phone && <p>Phone: {event.phone}</p>}
                                <p>Email: {event.email}</p>
                            </CardContent>
                            <CardActions>
                                <Link to={`/edit/${event.nanoid}`}><Button size="small">Edit</Button></Link>
                                <Button onClick={() => handleDelete(event.nanoid)} size="small">{confirmDelete.show && confirmDelete.id === event.nanoid ? "CONFIRM DELETE?" : "Delete"}</Button>
                            </CardActions>
                        </Card>
                    </div>
                )
            })}
        </div>
    )
}

export default Events