import { nanoid } from "nanoid"
import addDays from 'date-fns/addDays'

const defaultTemplates = [
    {
        nanoid: nanoid(),
        title: "‏",
        description: "",
        time: {
            start: new Date(Math.ceil(new Date().getTime() / 900000) * 900000),
            end: new Date(Math.ceil(new Date().getTime() / 900000) * 900000 + (3600 * 1000 * 1))
        },
        format: "hybrid",
        location: "",
        link: "",
        password: "",
        department: "",
        person: "",
        phone: "",
        email: "",
    },
    {
        nanoid: nanoid(),
        title: "Product Launch Event",
        description: "Generate media coverage and industry buzz prior to a product release.",
        time: {
            start: addDays(new Date(), 2).setHours(10, 0),
            end: addDays(new Date(), 2).setHours(12, 0)
        },
        format: "hybrid",
        location: "",
        link: "",
        password: "",
        department: "Project team",
        person: "John",
        phone: "",
        email: "john@domain.com",
    },
    {
        nanoid: nanoid(),
        title: "Shareholder Meetings",
        description: "Share recent performance, targets, future goals, and business strategies with shareholders.",
        time: {
            start: addDays(new Date(), 5).setHours(9, 0),
            end: addDays(new Date(), 5).setHours(11, 0)
        },
        format: "physical",
        location: "",
        link: "",
        password: "",
        department: "Finance",
        person: "Emily",
        phone: "",
        email: "emily@domain.com",
    },
    {
        title: "Team meeting",
        description: "Opportunities for team to discuss various business aspects.",
        time: {
            start: addDays(new Date(), 1).setHours(13, 0),
            end: addDays(new Date(), 1).setHours(14, 0)
        },
        format: "online",
        location: "",
        link: "",
        password: "",
        department: "Marketing",
        person: "Jane",
        phone: "",
        email: "jane@domain.com",
    }
]

export default defaultTemplates