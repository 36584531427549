import { nanoid } from "nanoid"

const defaultEvent = {
    nanoid: nanoid(),
    title: "",
    description: "",
    time: {
        start: new Date(Math.ceil(new Date().getTime() / 900000) * 900000),
        end: new Date(Math.ceil(new Date().getTime() / 900000) * 900000 + (3600 * 1000 * 1))
        },
    format: "physical",
    location: "",
    link: "",
    password: "",
    department: "",
    person: "",
    phone: "",
    email: "",
}

export default defaultEvent